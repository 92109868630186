import * as React from "react"
import { FC, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { usePrevious } from "../../../hooks/usePrevious"
import { lockScroll } from "../../../util/generalUtil"
import { formatPrice } from "../../../util/productUtil"
import {
  colors,
  FullLinkButton,
  getBasketSubtotal,
  OrderContextType,
  PrimaryButton,
  SidebarBasketItem,
} from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  width: 300px;
  position: fixed;

  right: ${({ visible, forceFullyVisible }) =>
    forceFullyVisible ? "0" : visible ? "-240px" : "-300px"};
  top: 100px;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid ${colors.sidebarBorderGrey};
  background-color: white;
  height: calc(100vh - 93px);
  display: flex;
  flex-direction: column;

  &:hover {
    right: ${({ visible }) => (visible ? "0" : "-300px")};
  }
`

const Title = styled.div`
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.sidebarBorderGrey};
`

const Items = styled.div`
  flex: 1;
  overflow: auto;
`
const Toolbar = styled.div`
  padding: 0px 10px 15px 10px;

  display: flex;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  &&& {
    flex: 1;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
`
const StyledFullLinkButton = styled(FullLinkButton)`
  margin-left: 5px;
`
const Meta = styled.div`
  padding: 10px 10px 5px 10px;
  font-size: 0.8em;
  border-top: 1px solid ${colors.sidebarBorderGrey};
`
const MetaItem = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`

const MetaTitle = styled.div`
  width: 150px;
  text-align: right;
`
const MetaContent = styled.div`
  width: 70px;
  text-align: right;
`

interface Props {
  onSubmit: () => void
  onCancel: () => void
  type: "GIFTING" | "SUPPLIES" | "SHOPFRONT"
  context: OrderContextType
}

const BasketSidebar: FC<Props> = ({ onSubmit, onCancel, type, context }) => {
  const {
    basket: { items, shippingTotal },
    isLoading,
  } = context
  const prevItems = usePrevious({ items })
  const [forceFullyVisible, setForceFullyVisible] = useState<boolean>(false)
  const ref = useRef(null)

  const min = 0
  const max = 0
  const avg = 0

  useEffect(() => {
    if ((!prevItems?.items || prevItems?.items?.length === 0) && items.length > 0) {
      setForceFullyVisible(true)
      setTimeout(() => {
        setForceFullyVisible(false)
      }, 1000)
    }

    if (prevItems?.items?.length < items.length) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
      }, 100)
    }
  }, [items])

  const handleEnter = () => {
    lockScroll(true)
  }
  const handleLeave = () => {
    lockScroll(false)
  }

  return (
    <Container
      visible={items.length > 0}
      forceFullyVisible={forceFullyVisible}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <Title>Your Basket</Title>
      <Items>
        {items.map(choice => (
          <SidebarBasketItem
            item={choice}
            key={`${choice.product.productId}-${choice.variant?.variantId}`}
            removeBasketItem={context.removeBasketItem}
            increaseBasketItemQuantity={context.increaseBasketItemQuantity}
            decreaseBasketItemQuantity={context.decreaseBasketItemQuantity}
          />
        ))}
        <div ref={ref} />
      </Items>
      <Meta>
        <MetaItem>
          <MetaTitle>Subtotal:</MetaTitle>
          <MetaContent>{formatPrice(getBasketSubtotal(items))}</MetaContent>
        </MetaItem>
        <MetaItem>
          <MetaTitle>Shipping:</MetaTitle>
          <MetaContent>
            {isLoading ? "Loading.." : formatPrice(shippingTotal) || formatPrice(0)}
          </MetaContent>
        </MetaItem>
        <MetaItem>
          <MetaTitle>
            <b>Per Recipient Total:</b>
          </MetaTitle>
          <MetaContent>
            <b>{formatPrice(getBasketSubtotal(items) + shippingTotal)}</b>
          </MetaContent>
        </MetaItem>
      </Meta>
      <Toolbar>
        <StyledPrimaryButton slim fullWidth onClick={onSubmit}>
          Checkout
        </StyledPrimaryButton>
        <StyledFullLinkButton onClick={onCancel}>Cancel</StyledFullLinkButton>
      </Toolbar>
    </Container>
  )
}

export default BasketSidebar
