import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import ProductFilterList from "../../components/product/ProductFilterList"
import BasketSidebar from "../gifting/catalogue/BasketSidebar"
import { SuppliesOrderContext } from "../../context/SuppliesOrderProvider"
import { SUPPLIES_FILTER_NODES } from "../../components/catalogue/filters/category-tree-filter/filterNodes"
import ProductSuppliesModal from "./ProductSuppliesModal"
import { ProductType } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  display: flex;
`
const SideBarContainer = styled.div`
  width: ${({ visible }) => (visible ? "60px" : "0")};
  transition: all 0.2s ease-in-out;
`
const StyledProductFilterList = styled(ProductFilterList)`
  padding: ${({ sidebarVisible }) => (sidebarVisible ? "10px" : "10px 40px")};
  transition: all 0.2s ease-in-out;
  flex: 1;
`

interface Props {}

const SuppliesCatalogue: FC<Props> = () => {
  const [modalMode, setModalMode] = useState<"order" | "gift-list" | "">("")
  const [modalProduct, setModalProduct] = useState<ProductType>(null)
  const context = useContext(SuppliesOrderContext)
  const {
    basket: { items },
    addBasketItems,
    cancel,
  } = context

  const handleAddToBasket = (product: ProductType) => {
    if (product.variants) {
      setModalProduct(product)
      setModalMode("order")
    } else {
      addBasketItems([{ product, variant: null, quantity: 1 }])

      // setIsCheckingOut(true)
    }
  }

  const handleModalClose = () => {
    setModalProduct(null)
    setModalMode("")
  }

  const handleBasketSubmit = () => {
    navigate("/supplies/checkout")
  }

  const isOrdering = items.length > 0

  const addToBasketControls = [
    {
      getLabel: () => "Add To Basket",
      isDisabled: () => false,
      onClick: handleAddToBasket,
    },
  ]

  return (
    <Container>
      <StyledProductFilterList
        category={"SUPPLIES"}
        sidebarVisible={isOrdering}
        primaryControls={addToBasketControls}
        secondaryControls={[]}
        onClick={product => setModalProduct(product)}
        showFilters
        categoryFilterNodes={SUPPLIES_FILTER_NODES}
      />
      <SideBarContainer visible={isOrdering}>
        <BasketSidebar
          onSubmit={handleBasketSubmit}
          onCancel={cancel}
          type={"SUPPLIES"}
          context={context}
        />
      </SideBarContainer>
      <ProductSuppliesModal
        mode={modalMode}
        productId={modalProduct?.productId}
        onClose={handleModalClose}
        show={!!modalProduct}
        priceIncludesShipping={false}
      />
    </Container>
  )
}

export default SuppliesCatalogue
